import './StateStandards.scss';

import { Link } from 'gatsby';
import React from 'react';

import { StateStandard } from 'types';

interface Props {
  stateStandards: StateStandard[];
}

const StateStandards: React.FC<Props> = (props) => {
  const { stateStandards } = props;

  return (
    <div className="StateStandards">
      <div className="StateStandards-header">
        <h4 className="StateStandards-heading">State Standards</h4>
      </div>

      {stateStandards.map((stateStandard) => (
        <Link
          className="StateStandards-row"
          key={stateStandard.identifier}
          to={`/state-standards/${stateStandard.identifier}`}
        >
          <div className="StateStandards-state">{stateStandard.state}</div>
          <div className="StateStandards-name">{stateStandard.name}</div>
        </Link>
      ))}
    </div>
  );
};

export default StateStandards;
