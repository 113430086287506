import './index.scss';

import React from 'react';
import { graphql } from 'gatsby';

import { config } from 'config';

import Layout from 'components/layout/Layout';

import { StateStandard } from 'types';

import StateStandards from 'components/state-standards/StateStandards';

interface Props {
  data: {
    allStateStandardsJson: {
      stateStandards: StateStandard[];
    };
  };
}

const StateStandardsPage: React.FC<Props> = (props) => {
  const { stateStandards } = props.data.allStateStandardsJson;

  if (!config.stateStandardsEnabled) {
    return null;
  }

  return (
    <Layout>
      <div className="StateStandardsPage-header">
        <div className="StateStandardsPage-header-copy">
          <h1>State Standards</h1>
        </div>
      </div>

      <StateStandards stateStandards={stateStandards} />
    </Layout>
  );
};

export default StateStandardsPage;

export const query = graphql`
  query StateStandardsQuery {
    allStateStandardsJson {
      stateStandards: nodes {
        ageRange
        citation
        name
        identifier
        link
        organization
        state
        stateAbbreviation
      }
    }
  }
`;
